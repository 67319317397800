<template>
    <div
        class="caroussel-block"
        :class="{
            'caroussel-block--property': variant === 'property'
        }"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }"
    >
        <BaseSection :background-color="backgroundColor">
            <template #title>
                <Skeletor
                    v-if="pending"
                    :shimmer="false"
                />
                <slot
                    v-else
                    name="title"
                />
            </template>

            <template #intro>
                <Skeletor
                    v-if="pending"
                    :shimmer="false"
                />
                <slot
                    v-else
                    name="description"
                />
            </template>
            <template #default>
                <template
                    v-if="variant === 'apartment'"
                >
                    <CallToAction
                        :hide-icon="true"
                        parent-component="CarousselBlock"
                    >
                        <template #cta>
                            {{ $t('book-this-apartment') }}
                        </template>
                    </CallToAction>
                </template>

                <ClientOnly>
                    <BaseSlider
                        v-if="pending"
                        :pagination-type="paginationType"
                        :items="[1,2,3,4]"
                    >
                        <template
                            v-for="index in [1,2,3,4]"
                            :key="`carousselPlaceholder-${index}`"
                            #[`slide-${index}`]
                        >
                            <div class="caroussel-block__item">
                                <Skeletor
                                    :shimmer="false"
                                    width="25rem"
                                    height="100%"
                                />
                            </div>
                        </template>
                    </BaseSlider>

                    <BaseSlider
                        v-else-if="filteredItems && filteredItems.length > 0"
                        :items="filteredItems"
                        :controls-enabled="enableControls"
                        :pagination-type="paginationType"
                        :padding="'compact'"
                        @mouseenter="enableControls = true"
                    >
                        <template
                            v-for="(item, index) in filteredItems"
                            :key="`carousselItem-${index}`"
                            #[`slide-${index}`]
                        >
                            <div
                                class="caroussel-block__item"
                                :class="{
                                    'caroussel-block__item--text': item.__typename === 'carousselItems_textItem_BlockType' || item.__typename === 'carousselItems_textItemLink_BlockType'
                                }"
                            >
                                <BaseImage
                                    v-if="item.__typename === 'carousselItems_imageItem_BlockType'"
                                    :image="item.image[0]"
                                    draggable="false"
                                    sizes="(min-width: 1024px) 606px, 328px"
                                />
                                <template v-if="item.__typename === 'carousselItems_textItemLink_BlockType'">
                                    {{ item.text }}
                                    <BaseButton
                                        :element="CraftLink"
                                        :link="item.linkField"
                                    >
                                        {{ item.linkField.text }}
                                    </BaseButton>
                                </template>
                                <template v-else>
                                    {{ item.text }}
                                </template>
                            </div>
                        </template>
                    </BaseSlider>
                </ClientOnly>
            </template>
        </BaseSection>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';

const { resolveColor, getTextColor } = useColorPalette();

const CraftLink = resolveComponent('CraftLink');

interface Props {
    backgroundColor?: keyof ColorPalette;
    variant?: 'property' | 'apartment';
    pending?: boolean;
    carousselItems?: any[];
    paginationType?: 'scroll' | 'pagination';
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'cream',
    pending: () => false,
    carousselItems: () => [],
    paginationType: () => 'scroll'
});

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));

const enableControls = ref(false);

const filteredItems = computed(() => {
    return props.carousselItems.filter((item) => {
        if (item.__typename === 'carousselItems_imageItem_BlockType' && !item.image?.[0]) {
            return;
        }
        if (item.__typename === 'carousselItems_textItemLink_BlockType' && !item.linkField) {
            return;
        }
        return item.__typename === 'carousselItems_imageItem_BlockType' || item.__typename === 'carousselItems_textItem_BlockType' || item.__typename === 'carousselItems_textItemLink_BlockType';
    });
});
</script>

<style lang="less">
.caroussel-block {
    background-color: var(--background-color);
    color: var(--text-color);

    .base-slider {
        --slider-gap: 2rem;
    }

    .call-to-action {
        align-items: center;
    }

    .base-section__body {
        grid-column: 1/-1;
    }

    .base-section__inner {
        max-width: 100%;
    }

    @media @q-md-max {
        .base-list--grid {
            margin-bottom: var(--spacing-md);
        }
    }
}

.caroussel-block.property__section {
    .slider__controls.slider__controls--scroll {
        height: 4rem;
        transform: none;
        margin-inline: auto;

        @media @q-md-min {
            margin-inline: clamp(calc(var(--grid-gutter-margin)), 100vw, calc(((100vw - var(--grid-maxWidth-page)) / 2) + var(--grid-gutter-margin)));
        }
    }
}

.caroussel-block.--property {
    .base-section__header {
        text-align: left;
    }

    @media  @q-lg-min {
        .base-section__header {
            grid-column: first / left-gutter;
            text-align: left;
        }

        .base-section__intro {
            grid-column: second / right-gutter;
        }
    }
}

.caroussel-block--property {
    .base-section__header {
        text-align: left;
    }

    @media  @q-lg-min {
        .base-section__header {
            grid-column: first / left-gutter;
            text-align: left;
        }

        .base-section__intro {
            grid-column: second / right-side-gutter;
        }
    }
}

.caroussel-block__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25rem;

    img,
    figure,
    picture {
        height: 100%;
        pointer-events: none;
    }

    a {
        align-self: flex-end;
    }

}

.caroussel-block__item--text {
    .typography-link();
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/2;
    padding-bottom: var(--spacing-xl);

    .slider__item:nth-child(even) & {
        justify-content: flex-end;
    }
}
</style>
